'use strict';

const $continueButton = document.querySelector('button.submit-payment');

const cssClass = {
    D_NONE: 'd-none',
    FONT_WEIGHT_BOLD: 'font-weight-bold'
};

const INVALID_CLASSNAME = 'is-invalid';

/**
 * function to proceed a save parsing
 * @param {string} element string what should be parsed
 * @returns {Object} result of parsing
 */
function tryParseJSON(element) {
    const AlertHandlerModel = require('./alertHandler');
    const alertHandler = new AlertHandlerModel();

    let result = null;

    try {
        result = JSON.parse(element);
    } catch (error) {
        alertHandler.showError('External server error. Try again');
    }

    return result;
}

/**
 * Get CSRF Token
 * @returns {string} - csrf token value
 */
function getCsrfToken() {
    let $element = document.querySelector('[name="csrf_token"]');

    if ($element && $element.value !== '') {
        return $element.value;
    }

    $element = document.querySelector('[data-tokenname="csrf_token"]');

    if ($element && $element.getAttribute('data-token') !== '') {
        return $element.getAttribute('data-token');
    }

    return '';
}

/**
 * Add csrf token param to url
 * @param {string} url - source url
 * @returns {string} - url with csrf_token param
 */
function getUrlWithCsrfToken(url) {
    const urlInstance = new URL(url, location.origin);

    urlInstance.searchParams.append('csrf_token', getCsrfToken());

    return urlInstance.toString();
}

/**
 * Depends on the value flag, sets style.display to the $continueButton
 * @param {boolean} flag Boolean value
 * @returns {void}
 */
function continueButtonToggle(flag) {
    const stage = window.location.hash.substring(1);

    if (stage === 'placeOrder' || stage === 'shipping' || stage === null || stage === '') {
        return;
    }

    $continueButton.style.display = flag ? 'none' : '';
}

/**
 * Return payment method name in lovercase
 * @param {string} paymentMethodName Payment method name
 * @returns {string} Paymnet method name
 */
function getPaymentMethodToLowerCase(paymentMethodName) {
    let paymentMethod = paymentMethodName.split('_');

    if (paymentMethod.length === 1) {
        return paymentMethodName;
    }

    paymentMethod = paymentMethod.map(function(element) {
        return element.charAt(0) + element.slice(1).toLocaleLowerCase();
    });

    return `${paymentMethod[0]} ${paymentMethod[1]}`;
}

/**
 * Appends a div to payment summary
 * @param {Element} $element HTML element
 * @param {*} text inner text
 * @returns {void}
 */
function appendDivToElement($element, text) {
    const $div = $element.cloneNode(false);
    const innerText = document.createTextNode(text);

    $div.removeAttribute('class');
    $div.appendChild(innerText);
    $element.appendChild($div);
}

/**
 * Updates checkout view
 * @param {Object} e Event object
 * @param {Object} data Data object
 */
function updateCheckoutView(e, data) {
    const $paymentSummary = document.querySelector('.summary-details .js-braintree-payment-details');
    const order = data.order;
    const payment = order.billing.payment;

    if (payment?.selectedPaymentInstruments?.length === 0 || !$paymentSummary) {
        return;
    }

    payment.selectedPaymentInstruments.forEach(selectedPaymentInstrument => {
        const paymentMethodId = selectedPaymentInstrument.paymentMethod;
        const fundingSource = selectedPaymentInstrument.fundingSource;

        $paymentSummary.innerHTML = '';

        if (fundingSource === window.braintreeConstants.PP_FUNDING_SOURCE_PAYLATER) {
            appendDivToElement($paymentSummary, window.braintreeConstants.PP_PAYLATER_PAYMENT_TYPE);
        } else if (fundingSource === window.braintreeConstants.PP_FUNDING_SOURCE_CARD) {
            appendDivToElement($paymentSummary, window.braintreeConstants.PP_DEBIT_CREDIT_PAYMENT_TYPE);
        } else {
            appendDivToElement($paymentSummary, getPaymentMethodToLowerCase(paymentMethodId));
        }

        if (selectedPaymentInstrument.maskedCreditCardNumber) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.maskedCreditCardNumber);
        }

        if (paymentMethodId === window.braintreeConstants.PAYMENT_METHOD_ID_PAYPAL) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.braintreePaypalEmail);
        } else if (paymentMethodId === window.braintreeConstants.PAYMENT_METHOD_ID_VENMO) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.braintreeVenmoUserId);
        }

        if (selectedPaymentInstrument.type) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.type);
        }

        appendDivToElement($paymentSummary, `${order.priceTotal.charAt(0)}${selectedPaymentInstrument.amount}`);
    });
}

/**
 * Validates whether input field is valid
 * @param {Object} field Input filed
 * @returns {boolean} true/false
 */
function isValidInputField(field) {
    if (!field.checkValidity()) {
        if (!field.classList.contains(INVALID_CLASSNAME)) {
            field.classList.add(INVALID_CLASSNAME);
        }

        return false;
    }

    if (field.checkValidity() && field.classList.contains(INVALID_CLASSNAME)) {
        field.classList.remove(INVALID_CLASSNAME);
    }

    return true;
}

/**
 * Gets Billing Address Form Values
 *
 * @returns {Object} with Billing Address
 */
function getBillingAddressFormValues() {
    const billingFormData = Object.fromEntries(new FormData(document.getElementById('dwfrm_billing')));

    return Array.from(Object.entries(billingFormData)).reduce(function(accumulator, [key, value]) {
        let elem = key.lastIndexOf('_');

        if (elem < 0) {
            accumulator[key] = value;
        } else {
            elem = key.substring(elem + 1);
            accumulator[elem] = value;
        }

        return accumulator;
    }, {});
}

/**
 * Gets Nonce depending on payment method name
 *
 * @param {string} paymentMethodName - payment method name
 * @returns {boolean} nonce exist
 */
function isNonceExist(paymentMethodName) {
    // Payment method name
    let pmName = paymentMethodName;

    // Сhange 'CREDIT_CARD' to 'CreditCard' in order to get braintreewCreditCardNonce input
    if (paymentMethodName === 'CREDIT_CARD') {
        pmName = 'CreditCard';
    }

    const $nonce = document.querySelector(`#braintree${pmName}Nonce`);

    if (!$nonce) {
        return false;
    }

    const nonceValue = $nonce.value;
    const $tab = document.querySelector(`.${pmName.toLowerCase()}-tab`);
    let isActiveTab;

    if ($tab) {
        isActiveTab = $tab.classList.contains('active');
    }

    return !isActiveTab && nonceValue;
}

/**
 * Removing BT payment from account page
 * @param {Object} e Event object
 * @returns {void}
 */
function removeBtPayment(e) {
    const loaderInstance = require('./loaderHelper');

    const target = e.target;

    const $loaderContainter = document.getElementById(target.getAttribute('data-loader'));
    const accountsLoader = loaderInstance($loaderContainter);

    accountsLoader.show();

    fetch(getUrlWithCsrfToken(`${window.braintreeUrls.deletePaymentUrl}?UUID=${target.getAttribute('data-id')}`))
        .then((response) => response.json())
        .then((data) => {
            document.getElementById(`uuid-${data.UUID}`).remove();

            if (data.newDefaultAccount) {
                document.querySelector(`#uuid-${data.newDefaultAccount} span`).classList.add(cssClass.FONT_WEIGHT_BOLD);
                document.querySelector(`#uuid-${data.newDefaultAccount} button.js-braintree-make-default-card`).classList.add(cssClass.D_NONE);
            }

            $('body').trigger('cart:update');
            accountsLoader.hide();
        })
        .catch(() => {
            location.reload();
            accountsLoader.hide();
        });
}

/**
 * Create formData from fields data
 *
 * @param {Object} paymentFields fields data values
 * @param {Object} fieldsData fields data values
 * @returns {Object} cart billing form data
 */
function createPaymentFormData(paymentFields, fieldsData) {
    let paymentFieldsParsed;

    if (paymentFields instanceof Object) {
        paymentFieldsParsed = paymentFields;
    } else {
        paymentFieldsParsed = tryParseJSON(paymentFields);
    }

    return Object.entries(paymentFieldsParsed).reduce(function(formData, entry) {
        const [key, field] = entry;

        if (field instanceof Object) {
            formData.append(field.name, fieldsData && fieldsData[key] !== null ? fieldsData[key] : field.value);
        }

        return formData;
    }, new FormData());
}

// eslint-disable-next-line require-jsdoc
function setDefaultProperty(params) {
    return fetch(getUrlWithCsrfToken(`${params.url}?UUID=${params.id}&pmID=${params.paymentMethodID}`))
        .then((response) => response.json())
        .then((data) => {
            document.querySelector(`#uuid-${data.newDefaultProperty} span`).classList.add(cssClass.FONT_WEIGHT_BOLD);
            document.querySelector(`.js-braintree-make-default-card.uuid-${data.newDefaultProperty}`).classList.add(cssClass.D_NONE);
            document.querySelector(`#uuid-${data.toRemoveDefaultProperty} span`).classList.remove(cssClass.FONT_WEIGHT_BOLD);
            document.querySelector(`.js-braintree-make-default-card.uuid-${data.toRemoveDefaultProperty}`).classList.remove(cssClass.D_NONE);
            params.loader.hide();
        })
        .catch(() => params.loader.hide());
}

/**
 * Checks authenticated customer, account list for default Payment Method
 *
 * @param {string} selector - querySelector
 * @returns {Object} default data attribute or null
 */
function getOptionByDataDefault(selector) {
    if (!document.querySelector(selector)) {
        return null;
    }

    return Array.apply(null, document.querySelector(selector).options).find(function(el) {
        return el.getAttribute('data-default') ? tryParseJSON(el.getAttribute('data-default')) : null;
    });
}

/**
 * Checks authenticated customer, account list for session Account
 *
 * @param {Object} params querySelector + el.id
 * @returns {Object} session account object
 */
function getSessionAccountOption(params) {
    return Array.apply(null, document.querySelector(params.querySelector).options).find(function(el) {
        return el.id === params.id && tryParseJSON(el.getAttribute('data-session-account'));
    });
}

/**
 * This method is called to remove active session account
 *
 * @param {Object} isCurrent determines if delete session account for current payment
 * @returns {void}
 */
function removeActiveSessionPayment(isCurrent = false) {
    const helpers = {
        PayPal: require('./paypal/components/payPalSessionAccount'),
        Venmo: require('./venmo/components/venmoSessionAccount'),
        GooglePay: require('./googlepay/components/googlePaySessionAccount'),
        CREDIT_CARD: require('./creditcard/components/creditCardSessionAccount'),
        SRC: require('./src/components/srcSessionAccount'),
        ApplePay: require('./applepay/helpers/applePayHelper')
    };

    const activePaymentMethods = [];

    let selectedPaymentMethod;

    document.querySelectorAll('.payment-options li').forEach(function(el) {
        activePaymentMethods.push(el.dataset.methodId);

        if (el.querySelector('.active')) {
            selectedPaymentMethod = el.dataset.methodId;
        }
    });

    if (isCurrent) {
        helpers[selectedPaymentMethod].removeSessionNonce();
    } else {
        // removes active session account but just in case if tab of currently active
        // session payment isn't active (buyer submited another payment method)
        const activePM = activePaymentMethods.find(function(el) {
            return isNonceExist(el);
        });

        if (activePM) {
            helpers[activePM].removeSessionNonce();
        }
    }
}

/**
 * Update Checkout Billing form values
 *
 * @param {Object} billingData fields data values
 */
function updateBillingFormValues(billingData) {
    const $billingFormFields = document.querySelectorAll('.billing-address select, .billing-address input, .contact-info-block input');

    $billingFormFields.forEach(function(el) {
        if (billingData[el.name]) {
            el.value = billingData[el.name];
        }
    });
}

/**
 * Returns selected option from select container
 * @param {Object} $selectContainer Select container
 * @returns {Object} Selected option
 */
function getSelectedOption($selectContainer) {
    const selectedOptionIndex = $selectContainer.selectedIndex;

    return $selectContainer.options[selectedOptionIndex];
}

/**
 * Returns payment field data to be send on backend
 * @param {Object} addressData Address data to be set
 * @param {string} paymentMethodName Payment method name
 * @returns {Object} payment data
 */
function getPaymentFieldsData(addressData, paymentMethodName) {
    return {
        firstName: addressData.firstName,
        lastName: addressData.lastName,
        address1: addressData.streetAddress,
        address2: addressData.extendedAddress || '',
        city: addressData.locality,
        postalCode: decodeURIComponent(addressData.postalCode),
        stateCode: addressData.stateCode || addressData.region,
        country: addressData.countryCodeAlpha2,
        phone: addressData.phone,
        paymentMethod: paymentMethodName
    };
}

// eslint-disable-next-line require-jsdoc
function getUpdatedStoreFrontBillingData(billingAddress) {
    const storeFrontBillingData = tryParseJSON(document.querySelector('.js-braintree-billing-payment-wrap')
        .getAttribute('data-billing-form-fields-names'));

    storeFrontBillingData.dwfrm_billing_addressFields_firstName = billingAddress.firstName;
    storeFrontBillingData.dwfrm_billing_addressFields_lastName = billingAddress.lastName;
    storeFrontBillingData.dwfrm_billing_addressFields_address1 = billingAddress.streetAddress;
    storeFrontBillingData.dwfrm_billing_addressFields_address2 = billingAddress.extendedAddress || '';
    storeFrontBillingData.dwfrm_billing_addressFields_city = billingAddress.locality;
    storeFrontBillingData.dwfrm_billing_addressFields_postalCode = decodeURIComponent(billingAddress.postalCode);
    storeFrontBillingData.dwfrm_billing_addressFields_states_stateCode = billingAddress.stateCode || billingAddress.region;
    storeFrontBillingData.dwfrm_billing_addressFields_country = billingAddress.countryCodeAlpha2;
    storeFrontBillingData.dwfrm_billing_contactInfoFields_phone = billingAddress.phone;

    return storeFrontBillingData;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {HTMLFormElement} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    Array.from(form.querySelectorAll('input, select')).forEach((element) => {
        if (element.classList.contains(INVALID_CLASSNAME)) {
            element.classList.remove(INVALID_CLASSNAME);
        }
    });
}

function displayErrorMessage(msg) {
    var status = 'alert-danger';

    if ($('#checkout-billing-error-message')) {
       
        $('#checkout-billing-error-message')
        .append('<div class="checkout-billing-message-alert text-center alert error ' + status + '">' + msg + '</div>');
    }
}


/**
 * Validate whole form
 * @param {HTMLFormElement} form - Form elemenent
 * @returns {boolean} - true if valid otherwise false
 */
function validateForm(form) {
    if (form.checkValidity && !form.checkValidity()) {
        Array.from(form.querySelectorAll('input, select')).forEach((element) => {
            if (!element.validity.valid) {
                console.log(element.className + '--> invalid value: ' + element.value);
                element.classList.add(INVALID_CLASSNAME);
                displayErrorMessage('Some issue in Billing Address. Please add your billing address again and try again.');
            }
        });

        return false;
    }

    return true;
}

/**
 * It checks for duplicated Credit Card and returns response as JSON object.
 * @param {string} body - request body.
 * @returns {Promise} A promise that resolves to a JSON object.
 */
function checkForDuplicatedCC(body) {
    return fetch(getUrlWithCsrfToken(window.braintreeUrls.validateCreditCardUrl), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
    }).then(response => response.json());
}

/**
 * Gets applicable shipping methods
 * @param {string} url Validate address url
 * @returns {Object} Object with applicable shipping options
 */
async function getApplicableShippingOptions(url) {
    let shippingOptions;

    const response = await fetch(getUrlWithCsrfToken(url));

    if (response.ok) {
        const data = await response.json();

        shippingOptions = data.applicableShippingMethods;
    }

    return shippingOptions;
}

/**
 * Updates order amount when a certain shipping option gets selected
 * @param {string} shippingOptionID the id of the user choosen shipping method from GP pop-up
 * @returns {Promise} resolved promise which returns the amount
 */
function updateAmountForShippingOption(shippingOptionID) {
    return fetch(getUrlWithCsrfToken(window.braintreeUrls.getAmountForShippingOption), {
        method: 'POST',
        body: shippingOptionID
    })
        .then((response) => {
            if (!response.ok) {
                window.location.reload();
            }

            return response.json();
        })
        .then((data) => {
            return data.amount;
        });
}

/**
 * Handle Submit Payment Errors from response data.
 * @param {Object} response - Response data.
 * @param {Object} alertHandler - Alert instance.
 * @returns {boolean} - true if there is an error otherwise.
 */
function handleSubmitPaymentErrors(response, alertHandler) {
    if (response.error) {
        let errorMessage = '';

        if (response.fieldErrors.length) {
            response.fieldErrors.forEach((error, index) => {
                const keys = Object.keys(error);

                if (keys.length) {
                    errorMessage += `${keys[index].replace('dwfrm_billing_', '')
                        .replace('_', ' ')} ${response.fieldErrors[index][keys[index]]}. `;
                }
            });

            alertHandler.showError(errorMessage);
        }

        if (response.serverErrors.length) {
            response.serverErrors.forEach((error) => {
                errorMessage += `${error}. `;
            });

            alertHandler.showError(errorMessage);
        }

        // Usually in case of any errors "cartError" will be "true"
        if (response.cartError) {
            window.location.href = response.redirectUrl;
        }

        return true;
    }

    return false;
}

module.exports = {
    continueButtonToggle,
    updateCheckoutView,
    isValidInputField,
    getBillingAddressFormValues,
    removeBtPayment,
    createPaymentFormData,
    isNonceExist,
    setDefaultProperty,
    getOptionByDataDefault,
    removeActiveSessionPayment,
    getSessionAccountOption,
    updateBillingFormValues,
    getSelectedOption,
    getPaymentFieldsData,
    getUpdatedStoreFrontBillingData,
    clearForm,
    validateForm,
    checkForDuplicatedCC,
    getUrlWithCsrfToken,
    getApplicableShippingOptions,
    updateAmountForShippingOption,
    tryParseJSON,
    appendDivToElement,
    handleSubmitPaymentErrors
};
