'use strict';

const lpmModel = require('../braintreesdk/braintreeLpmModel');
const helper = require('../../helper');

/**
 * Whether 'details' parameter is exist returns 'details' or shipping address if not exist
 *
 * @param {Object} details Details object from StartPaymentPayload
 * @param {string} email Email Address
 * @returns {Object} Details object
 */
function getPaymentDetailsParameter(details, email) {
    const shippingAddress = lpmModel.getShippingAddress();

    shippingAddress.email = email;

    return jQuery.isEmptyObject(details) ? shippingAddress : details;
}

/**
 *
 * @param {Object} lpmConfigs LPM configs object
 * @param {string} paymentMethodName Payment method name
 * @param {string} email Email address
 * @param {Object} startPaymentPayload StartPaymnetPayload
 * @returns {Promise} Promise resolved with 'redirectUrl'
 */
function confirmPayment(lpmConfigs, paymentMethodName, email, startPaymentPayload) {
    return fetch(helper.getUrlWithCsrfToken(lpmConfigs.paymentConfirmUrl), {
        method: 'POST',
        body: JSON.stringify({
            nonce: startPaymentPayload.nonce,
            lpmName: paymentMethodName,
            details: getPaymentDetailsParameter(startPaymentPayload.details, email),
            deviceData: JSON.stringify({ correlation_id: startPaymentPayload.correlationId })
        })
    });
}

/**
 * Creates a redirecting form to Order-Confirm endpoint
 * @param {Object} params  The helping object for creating a from
 * @returns {Object} form element
 */
function createConfirmForm(params) {
    const form = document.createElement('form');
    const orderIdInput = document.createElement('input');
    const orderTokenInput = document.createElement('input');

    form.method = 'POST';
    form.action = params.url;

    orderIdInput.value = params.orderID;
    orderIdInput.name = 'orderID';
    form.appendChild(orderIdInput);

    orderTokenInput.value = params.orderToken;
    orderTokenInput.name = 'orderToken';
    form.appendChild(orderTokenInput);

    document.body.appendChild(form);

    return form;
}

/**
 * Prepare and submits form in order to confirm order with Lpm
 * @param {string} redirectUrl Redirect Url
 */
function processLpmConfirmForm(redirectUrl) {
    const splitUrl = redirectUrl.split('?');
    const url = splitUrl.shift();
    const searchParams = new URLSearchParams(splitUrl.join());
    const formParam = {
        orderID: searchParams.get('orderID'),
        orderToken: searchParams.get('orderToken'),
        url: url
    };

    const form = createConfirmForm(formParam);

    form.submit();
}

module.exports = {
    getPaymentDetailsParameter,
    confirmPayment,
    processLpmConfirmForm,
    createConfirmForm
};
