'use strict';

// Helpers
const lpmHelper = require('../helpers/lpmHelper');
const loaderInstance = require('../../loaderHelper');
const lpmPaymentProcessingHelper = require('../helpers/lpmPaymentProcessorHelper');

const LpmAlertHandlerModel = require('../alerthandler/lpmAlertHandlerModel');
const helper = require('../../helper');
const alertHandler = new LpmAlertHandlerModel();

const $braintreeLpmLoader = document.querySelector('.js-braintree-loader-container');

// Global variables
let lpmConfigs;
let loader;
let lpmModel;

/**
 * Opens LPM page and process a payment
 * @param {Object} reqData Options object
 * @param {string} paymentMethodName Payment method name
 */
function startLpmPayment(reqData, paymentMethodName) {
    lpmModel.startPayment(reqData)
        .then(function(startPaymentPayload) {
            // Handles LPM processing messages on the Billing-Checkout Page
            alertHandler.hideAlerts();
            alertHandler.showInfo(alertHandler.alertMessages.LPM_PAYMENT_SUCCESS);

            // Sends payment details to the backend
            return lpmPaymentProcessingHelper.confirmPayment(lpmConfigs,
                paymentMethodName,
                reqData.email,
                startPaymentPayload);
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(data) {
            lpmPaymentProcessingHelper.processLpmConfirmForm(data.redirectUrl);
            loader.hide();
        })
        .catch(function(error) {
            loader.hide();
            alertHandler.handleLpmError(error);
        });
}

/**
 * Prepares and checks all necessary options for start LPM payment
 * @param {Object} event On LPM button click event
 */
function onLpmButtonClick(event) {
    const $email = document.querySelector('.customer-summary-email');
    const paymentMethodName = this.dataset.localPaymentMethodName;

    alertHandler.hideAlerts();
    event.preventDefault();

    try {
        if (lpmConfigs.options.amount === 0) {
            loader.hide();
            alertHandler.handleZeroAmountOnCheckoutError(paymentMethodName);
        }

        fetch(helper.getUrlWithCsrfToken(lpmConfigs.getOrderInfoUrl))
            .then((response) => response.json())
            .then((data) => {
                loader.show();
                alertHandler.showInfo(alertHandler.alertMessages.LPM_PAYMENT_PROCESSING);

                // Updates shipping address and amount of order from server
                lpmModel.updateLpmOrderData(data.shippingAddress, data.amount);

                // Creates all options for initiating the local payment payment flow.
                const reqData = lpmModel.createRequestData(paymentMethodName, $email.textContent);

                startLpmPayment(reqData, paymentMethodName);
            })
            // Handles errors form Braintree-GetOrderInfo endpoint
            .catch((error) => {
                loader.hide();
                alertHandler.showError(error.responseJSON.message);
            });
        loader.hide();
    } catch (error) {
        alertHandler.showError(error);
    }
}

/**
 * Inits LPM functionality on the Checkout-Billing Page
 */
function initLpm() {
    lpmModel.initLpm()
        .then(function(response) {
            if (response.createLpmClickListener) {
                document.querySelectorAll('.js-braintree-lpm-button').forEach((button) => {
                    button.addEventListener('click', onLpmButtonClick);
                });
            }
        })
        .catch(function(error) {
            alertHandler.showError(error.message);
        });
}

/**
 * Inits LPM payment processing components
 * @param {Object} braintreeLpmModel LPM model
 */
function init(braintreeLpmModel) {
    loader = loaderInstance($braintreeLpmLoader);
    lpmConfigs = lpmHelper.getBraintreeLpmConfigs();
    lpmModel = braintreeLpmModel;

    initLpm();
}

module.exports = {
    init
};
