'use strict';

const $googlepayButton = document.querySelector('.js-braintree-googlepay-button');
const $googlepayContent = document.querySelector('.js-braintree-googlepay-content');

// Helpers
const helper = require('../../helper');
const googlePayHelper = require('../helpers/googlePayHelper');

const usedGooglePayAccountClassName = 'used-googlepay-account';
const usedGooglePayAccountHideClassName = 'used-googlepay-account-hide';

/**
 * Shows session Google Pay account on the Billing Page
 * @param {string} cardDescription Session card description
 */
function showGooglepayAccount(cardDescription) {
    const $newGPAccount = document.getElementById('newGPAccount');
    const $gpAccount = document.querySelector('.js-braintree-used-googlepay-account');
    const $sessionAccount = document.getElementById('sessionGPAccount');

    $newGPAccount.removeAttribute('selected');

    // Sets new session account
    $sessionAccount.classList.remove(usedGooglePayAccountHideClassName);
    $sessionAccount.classList.add(usedGooglePayAccountClassName);
    $sessionAccount.text = cardDescription;
    $sessionAccount.selected = true;
    $sessionAccount.setAttribute('data-session-account', 'true');

    $gpAccount.classList.remove(usedGooglePayAccountHideClassName);
    $gpAccount.classList.add(usedGooglePayAccountClassName);

    $googlepayButton.classList.add('d-none');
    $googlepayContent.setAttribute('data-paypal-is-hide-continue-button', false);
    helper.continueButtonToggle(false);
}

/*
    Remove method was used and change appearance of Google Pay tab
* */
function removeSessionNonce() {
    const $gpAccount = document.querySelector('.js-braintree-used-googlepay-account');

    $gpAccount.classList.remove(usedGooglePayAccountClassName);
    $gpAccount.classList.add(usedGooglePayAccountHideClassName);

    const $sessionAccount = document.getElementById('sessionGPAccount');

    if ($sessionAccount) {
        $sessionAccount.classList.add(usedGooglePayAccountHideClassName);
        $sessionAccount.classList.remove(usedGooglePayAccountClassName);
        $sessionAccount.value = '';
        $sessionAccount.text = '';
        $sessionAccount.setAttribute('data-session-account', false);
        $sessionAccount.setAttribute('data-save', false);
    }

    document.getElementById('braintreeGooglePayNonce').value = '';
    document.getElementById('braintreeGooglePayBillingAddress').value = '';
    document.getElementById('braintreeGooglepayPaymentType').value = '';
    document.getElementById('braintreeGooglePayDeviceData').value = '';

    document.getElementById('newGPAccount').selected = true;
    googlePayHelper.googlePayContentTabHandler(false);
}

module.exports = {
    showGooglepayAccount,
    removeSessionNonce
};
