'use strict';

const helper = require('../../helper');
const PayPalScriptLoading = class {
    constructor() {
        this.clientToken = this.getClientToken();
        this.configurations = this.getConfigurations();
    }

    getValueByAttributeName(attributeName) {
        const $element = document.querySelector(`[${attributeName}]`);

        return $element ? $element.getAttribute(attributeName) : null;
    }

    getClientToken() {
        return this.getValueByAttributeName('data-bt-client-token');
    }

    getConfigurations() {
        const attributeName = 'data-braintree-config';
        const $element = document.querySelector(`[${attributeName}*="paypalConfig"]`);

        return $element ? helper.tryParseJSON($element.getAttribute(attributeName)) : null;
    }

    createClientInstance() {
        return braintree.client.create({
            authorization: this.clientToken
        });
    }

    createPayPalCheckoutInstance() {
        return this.createClientInstance().then((clientInstance) => {
            return braintree.paypalCheckout.create({
                client: clientInstance
            });
        });
    }

    getPayPalSdkUrlCongifuration() {
        const config = { components: 'buttons,messages' };

        const {
            options: configOptions,
            changePMButtonEnabled: isChangePaymentButtonActive
        } = this.configurations;

        const isVaultMode = configOptions.flow !== 'checkout';
        const enableFundingList = configOptions.enableFundingList;
        let disableFundingList = configOptions.disableFundingList;

        if (isVaultMode) {
            config.vault = true;
        } else {
            config.commit = false;
            config.intent = configOptions.intent;
            config.currency = configOptions.currency;
        }

        // Functionality for disabling Debit/Credit Card Button when Change Payment Method Button is enabled
        if (isChangePaymentButtonActive && disableFundingList !== undefined) {
            disableFundingList += ',card';
        } else if (isChangePaymentButtonActive && disableFundingList === undefined) {
            disableFundingList = 'card';
        }

        if (enableFundingList !== undefined) {
            config['enable-funding'] = enableFundingList;
        }

        if (disableFundingList !== undefined) {
            config['disable-funding'] = disableFundingList;
        }

        return config;
    }

    loadPayPalSDK() {
        // eslint-disable-next-line no-undef
        const promise = new Promise(resolve => resolve(this));

        if (window.isPayPalSDKLoaded) {
            return promise;
        }

        // In case if paypal SDK is loaded only for credit messages, we need to delete it
        // and load again to avoid getting unhandled errors in console
        if (window.isPayPalCreditMessageLoaded) {
            delete window.paypal;
        }

        if (!this.clientToken || !this.configurations) {
            // eslint-disable-next-line no-console
            console.warn('An error has occurred. Failed to find client token or configuration.');

            return promise;
        }

        const options = this.getPayPalSdkUrlCongifuration();

        return this.createPayPalCheckoutInstance().then((payPalCheckoutInstance) => {
            return payPalCheckoutInstance.loadPayPalSDK(options).then(() => {
                window.isPayPalSDKLoaded = true;

                return this;
            });
        });
    }

    /**
     * This callback runs after the script is loaded
     * @callback onLoadCallback
     */

    /**
     * @param {onLoadCallback} [onLoadCallback] - A callback runs after the script is loaded
     * @returns {ScriptLoading} - A ScriptLoading object
     */
    loadConnectWithPayPal(onLoadCallback) {
        const value = this.getValueByAttributeName('data-cwpp-sdk');

        if (!value) {
            return this;
        }

        const script = document.createElement('script');

        script.src = value;
        script.async = true;

        if (onLoadCallback && typeof onLoadCallback === 'function') {
            script.onload = onLoadCallback;
        }

        script.onerror = () => {
            // eslint-disable-next-line no-console
            console.error(`An upload error occurred and the file ${value} could not be uploaded.`);
        };

        document.body.appendChild(script);

        return this;
    }

    loadPayPalCreditMessages() {
        const promise = new Promise(resolve => resolve(this));

        if (window.isPayPalCreditMessageLoaded || window.isPayPalSDKLoaded) {
            return promise;
        }

        if (!this.clientToken) {
            // eslint-disable-next-line no-console
            console.warn('An error has occurred. Failed to find client token.');

            return promise;
        }

        const options = { components: 'messages', dataAttributes: { namespace: 'paypalSDK' } };

        return this.createPayPalCheckoutInstance().then((payPalCheckoutInstance) => {
            return payPalCheckoutInstance.loadPayPalSDK(options).then(() => {
                window.isPayPalCreditMessageLoaded = true;

                return this;
            });
        });
    }
};

module.exports = PayPalScriptLoading;
