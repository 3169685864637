'use strict';

const ApmaModel = require('../models/apma');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');
const helper = require('../../helper');

/**
 * Function which initiate PayPal functionality on the Account Page
 * @param {Object} btClientInstancePromise BT Client Instance promise
 */
function init(btClientInstancePromise) {
    const payPalButtonSelector = '#js-apma-button-paypal';
    const $payPalButton = document.querySelector(payPalButtonSelector);

    if ($payPalButton) {
        const redirectUrl = $payPalButton.getAttribute('data-redirect-url');
        const isAccountPage = redirectUrl.toLowerCase().includes(window.braintreeConstants.PAGE_FLOW_ACCOUNT);
        const addressObject = helper.tryParseJSON($payPalButton.getAttribute('data-address-object'));
        const payPalConfigurations = helper.tryParseJSON($payPalButton.getAttribute('data-braintree-config'));
        const addPayPalAccountUrl = $payPalButton.getAttribute('data-paypal-add-account-handler');

        const errorMessages = payPalConfigurations.messages;

        const alertHandler = new PaypalAlertHandlerModel(errorMessages);
        const PayPalAPMA = new ApmaModel(
            alertHandler,
            btClientInstancePromise,
            payPalButtonSelector,
            payPalConfigurations,
            {
                addPayPalAccountUrl,
                addressObject,
                isAccountPage,
                redirectUrl
            }
        );

        PayPalAPMA.initPayment();
        PayPalAPMA.initAddButtonEvent();
    }
}

module.exports = {
    init
};
