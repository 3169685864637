'use strict';

const srcHelper = require('../helpers/srcHelper');
const loaderInstance = require('../../loaderHelper');

// Models
const SrcCheckoutModel = require('../models/srcCheckoutModel');
const SrcAlertHandlerModel = require('../alerthandler/srcAlertHandlerModel');

const $braintreeBillingPageSrcButton = document.querySelector('.js-braintree-billing-src-button');

/**
 * Inits SRC account functionality on the Checkout Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
    const $braintreeSrcLoader = document.querySelector('.js-braintree-src-loader');
    const $totalAmount = document.querySelector('.grand-total-sum');
    const srcLoader = loaderInstance($braintreeSrcLoader);
    const srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
    const $braintreeSrcDeviceDataInput = document.querySelector('input[name=braintreeSrcDeviceDataInput]');
    const alertHandler = new SrcAlertHandlerModel(srcButtonConfigurations.messages);

    const SrcCheckoutModelInstance = new SrcCheckoutModel(clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        srcLoader,
        alertHandler);

    // Hides errors on the Checkout/Billing Page
    $braintreeBillingPageSrcButton.addEventListener('click', alertHandler.hideAlerts);

    SrcCheckoutModelInstance.initSrcButton();

    // Tracks cases when total amount equal zero and throw an error
    srcHelper.zeroAmountSrcButtonBehavior($totalAmount, alertHandler);

    // Click SRC tab if session payment ID is SRC and session account value is filled
    // in case of checkout through saved SRC card session payment id will be SRC
    // but session account value will be empty
    const sessionPaymentMethodId = srcButtonConfigurations.sessionPaymentMethodId;
    const isSrcSessionAccountExist = document.getElementById('sessionSrcAccount')?.value !== '';

    if (sessionPaymentMethodId === 'SRC' && isSrcSessionAccountExist) {
        document.querySelector('.src-tab').click();
    }

    // Close alert saying to chose a diffrent shipping option on submit shipping button click
    // in case of express checkout with address to which default shipping option doesn't deliver
    // when a customer is redirected to checkout stage shipping
    const $submitShippingBtn = document.querySelector('.submit-shipping');

    $submitShippingBtn.addEventListener('click', alertHandler.hideControllerCreatedAlerts);
}

module.exports = {
    init
};
