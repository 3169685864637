'use strict';

const braintreeGeneral = require('../../braintreeGeneral');

/**
 * Constructor of VaultMgr model
 * @param {Promise} personalizedClientTokenPromise BT clietn instance promise
 */
function CreditCardVaultManagerModel(personalizedClientTokenPromise) {
    this.customerPaymentMethodsFromVaultPromise = braintreeGeneral.getBtCustomerPaymentMethods(personalizedClientTokenPromise);
}

/**
 * Find CC by data within VaultMgr
 * @param {Promise} customerPaymentMethodsFromVaultPromise Array with Payment Methods from VaultMgr
 * @param {string} lastFour Last four credit card digits
 * @param {string} cardType Credit Card type (Visa, Mastercard)
 * @param {Object} expiration Month and year
 * @returns {Promise} Promise with found Credit Card
 */
function getCardFromVaultMgrByData(customerPaymentMethodsFromVaultPromise, lastFour, cardType, expiration) {
    return customerPaymentMethodsFromVaultPromise
        .then(function(vaultMgrPaymentMethods) {
            return vaultMgrPaymentMethods.find(function(paymentMethod) {
                const isCardType = paymentMethod.type === 'CreditCard' || paymentMethod.type === 'VisaCheckoutCard';
                const pmDetails = paymentMethod.details;
                const isExpirationMatched = pmDetails.expirationMonth === expiration.month && pmDetails.expirationYear.endsWith(expiration.year);

                return isCardType && isExpirationMatched && pmDetails.cardType.toLowerCase() === cardType && pmDetails.lastFour === lastFour;
            });
        });
}

/**
 * Return CC from VaultManager by passed data
 * @param {string} lastFour Last four credit card digits
 * @param {string} cardType Credit Card type (Visa, Mastercard)
 * @param {Object} expiration Month and year
 * @returns {Promise} Promise with nonce or empty string
 */
CreditCardVaultManagerModel.prototype.getNonceByCardData = function(lastFour, cardType, expiration) {
    return getCardFromVaultMgrByData(this.customerPaymentMethodsFromVaultPromise,
        lastFour,
        cardType,
        expiration);
};

module.exports = CreditCardVaultManagerModel;
