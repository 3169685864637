'use strict';

const helper = require('../../helper');

/**
 * General validation of params needed for initialization of hosted fields
 * @param {Object} $braintreeCreditCardFieldsContainer Braintree Credit Card fields container
 * @param {Object} braintreeCreditCardConfig Braintree Credit Card configurations (they came from server via data attr)
 * @returns {Object} object with "error" boolean flag and array of error messages from "massages" attr
 */
function generalHfValidation($braintreeCreditCardFieldsContainer, braintreeCreditCardConfig) {
    const response = {
        error: false,
        messages: ''
    };

    try {
        const isHostedFieldsWasInited = helper.tryParseJSON($braintreeCreditCardFieldsContainer.getAttribute('data-is-inited'));
        const isBraintreeCreditCardConfigValid = typeof braintreeCreditCardConfig !== 'object' || braintreeCreditCardConfig !== null;

        if (isHostedFieldsWasInited) {
            // do not add error msg since this case is expected (hosted fields should be inited just once)
            response.error = true;
        }

        if (!isBraintreeCreditCardConfigValid) {
            response.error = true;
            response.messages = '.js-braintree-credit-card-fields has not valid data-braintree-config';
        }
    } catch (error) {
        response.error = true;
        response.messages = error;
    }

    return response;
}

/**
 * Init Credit Card fields functionality
 * @param {Object} btCreditCardModel Braintree Credit Card model
 * @param {Constructor} alertHandlerModel Alert handling model
 * @param {Object} hostedFieldsInstance A Hosted fields instance
 */
function init(btCreditCardModel, alertHandlerModel, hostedFieldsInstance) {
    alertHandlerModel.hideAlerts();

    document.querySelectorAll('.js-braintree-credit-card-fields').forEach(function(el) {
        try {
            const $braintreeCreditCardFieldsContainer = el;
            const braintreeCreditCardConfig = helper.tryParseJSON($braintreeCreditCardFieldsContainer.getAttribute('data-braintree-config'));
            const generalHostedFieldsValidation = generalHfValidation($braintreeCreditCardFieldsContainer, braintreeCreditCardConfig);

            if (generalHostedFieldsValidation.error && generalHostedFieldsValidation.messages.length) {
                throw generalHostedFieldsValidation.messages;
            }

            // Fraud Data will be only collected in case if corresponding Site Preference is enabled
            // For more details go inside "btCreditCardModel.collectFraudData" function logic
            btCreditCardModel.collectFraudData()
                .then(function(fraudDataPayload) {
                    const $braintreeDeviceData = document.getElementById('braintreeDeviceData');

                    if (fraudDataPayload.deviceData) {
                        $braintreeDeviceData.value = fraudDataPayload.deviceData;
                    }

                    // Init "Credit Card Hosted Fields"
                    return hostedFieldsInstance.initHostedFields();
                })
                .catch(function(error) {
                    alertHandlerModel.handleCreditCardError(error);
                });

            // Update order data inside BT model each time when buyer update checkout view
            $('body').on('checkout:updateCheckoutView', btCreditCardModel.updateOrderData);

            // To prevent from repeatable Credit Card hosted fields initialization
            $braintreeCreditCardFieldsContainer.setAttribute('data-is-inited', true);
        } catch (error) {
            alertHandlerModel.handleCreditCardError(error);
        }
    });
}

module.exports = {
    init
};
