'use strict';

const braintreeGeneral = require('../../braintreeGeneral');
const braintreeGooglePaySDK = require('../braintreesdk/braintreeGooglePaySDK');
const helper = require('../../helper');

/* global Promise braintree $ */

// Global variables
// Is Froud tools enabled
let isFtEnabled;
let btClientInstancePromise;
let googlePaymentInstance;
let paymentsClient;

let amount;
let currencyCode;

let shippingOptionId;

/**
 * Init Baintree Credit Card model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeGooglePaySDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    isFtEnabled = isFraudToolsEnabled;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    let response;

    if (isFtEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: 'Fraud Data collection isn\t enabled via Custom Site Preference'
        });
    }

    return response;
}

/**
 * Preparing googlePay payment
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Promise} Promise with resolve 'true' if ready to pay
 */
function initGooglePay(googleMerchantId) {
    return braintreeGooglePaySDK.createGooglePayment(googleMerchantId)
        .then(function(gpInstance) {
            const allowedPaymentMethods = gpInstance.createPaymentDataRequest().allowedPaymentMethods;

            googlePaymentInstance = gpInstance;

            return paymentsClient.isReadyToPay({
                // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: allowedPaymentMethods
            });
        });
}

/**
 * Creates a configuration object for use in the loadPaymentData method.
 * @param {Object} options Object with payment options
 * @returns {Object} Configuration object
 */
function createPaymentDataRequest(options) {
    return braintreeGooglePaySDK.createPaymentDataRequest(googlePaymentInstance, options, amount);
}

/**
 * Parse the response from the tokenization.
 * @param {Object} paymentData The response back from the Google Pay tokenization.
 * @returns {Promise} Promise that resolve with a tokenizePayload
 */
function parseResponse(paymentData) {
    return braintreeGooglePaySDK.parseResponse(paymentData, googlePaymentInstance);
}

/**
 * Update order amount
 * @returns {Promise} resolved promise which updates the amount
 */
function googlePayUpdateAmount() {
    const config = helper.tryParseJSON(document.querySelector('.js-braintree-googlepay-button').getAttribute('data-braintree-config'));

    return fetch(helper.getUrlWithCsrfToken(config.getOrderInfoUrl))
        .then((response) => {
            if (!response.ok) {
                window.location.reload();
            }

            return response.json();
        })
        .then((data) => {
            amount = data.amount;
            currencyCode = data.currencyCode;
        });
}

/**
 * Handles dynamic buy flow shipping address and shipping options callback intents.
 * @param {Object} intermediatePaymentData response from Google Pay API a shipping address or shipping option is selected in the payment sheet.
 * @returns {Promise} Promise of PaymentDataRequestUpdate object to update the payment sheet.
 */
async function onPaymentDataChanged(intermediatePaymentData) {
    const googlePayConstants = require('../constants/googlePayPaymentProcessingConstants');
    const GooglePayAlertHandlerModel = require('../alerthandler/googlePayAlertHandlerModel');

    const alertHandler = new GooglePayAlertHandlerModel();
    const shippingAddress = intermediatePaymentData.shippingAddress;
    const paymentDataRequestUpdate = {};

    let isShippingMethodApplicable;

    shippingOptionId = intermediatePaymentData.shippingOptionData.id;

    if (shippingAddress) {
        const city = shippingAddress.locality;
        const stateCode = shippingAddress.administrativeArea;
        const countryCode = shippingAddress.countryCode;
        const postalCode = shippingAddress.postalCode;
        const addressQueryString = `city=${city}&stateCode=${stateCode}&countryCode=${countryCode}&postalCode=${postalCode}`;
        const paymentMethodId = window.braintreeConstants.PAYMENT_METHOD_ID_GOOGLEPAY;
        const url = `${window.braintreeUrls.getApplicableShippingOptions}?paymentMethodId=${paymentMethodId}&${addressQueryString}`;

        const applicableShippingMethods = await helper.getApplicableShippingOptions(url);

        isShippingMethodApplicable = applicableShippingMethods.find((shippingMethod) => shippingMethod.id === shippingOptionId);
    }

    if (intermediatePaymentData.callbackTrigger === googlePayConstants.INTENT_INITIALIZE
        || intermediatePaymentData.callbackTrigger === googlePayConstants.INTENT_SHIPPING_ADDRESS) {
        // Showing error if store doesn't ship to the address selected in Google Pay
        if (!shippingAddress) {
            return Promise.reject(paymentDataRequestUpdate);
        }

        if (!isShippingMethodApplicable) {
            paymentDataRequestUpdate.error = alertHandler.getPaymentDataError(googlePayConstants.INTENT_SHIPPING_ADDRESS);
        }
    } else if (intermediatePaymentData.callbackTrigger === googlePayConstants.INTENT_SHIPPING_OPTION) {
        // Showing error if store doesn't ship to the address selected in Google Pay
        if (!isShippingMethodApplicable) {
            paymentDataRequestUpdate.error = alertHandler.getPaymentDataError(googlePayConstants.INTENT_SHIPPING_OPTION);
        }

        // Recalculation amount taking into account new shipping price
        // Showing new amount in Google Pay
        const totalAmount = await helper.updateAmountForShippingOption(shippingOptionId);

        paymentDataRequestUpdate.newTransactionInfo = {
            currencyCode: currencyCode,
            totalPriceStatus: 'FINAL',
            totalPrice: totalAmount,
            totalPriceLabel: 'Final'
        };
    }

    return Promise.resolve(paymentDataRequestUpdate);
}

/**
 * Inits 'Google Pay' payment client object into braintreeGooglePayModel
 * @param {string} instanceType The type of the instance TEST/PRODUCTION
 * @param {string} flow Page flow
 */
function initPaymentsClient(instanceType, flow) {
    const paymentDataCallbacks = {
        onPaymentAuthorized: () => Promise.resolve({ transactionState: 'SUCCESS' })
    };

    if (flow === window.braintreeConstants.PAGE_FLOW_CART) {
        paymentDataCallbacks.onPaymentDataChanged = onPaymentDataChanged;
    }

    paymentsClient = new google.payments.api.PaymentsClient({ // eslint-disable-line no-undef
        environment: instanceType, // TEST/PRODUCTION
        paymentDataCallbacks: paymentDataCallbacks
    });
}

/**
 * Gets 'Google Pay' payment client object
 * @returns {Object} 'Google Pay' payment client object
 */
function getPaymentsClient() {
    return paymentsClient;
}

module.exports = {
    init,
    collectFraudData,
    initGooglePay,
    createPaymentDataRequest,
    parseResponse,
    googlePayUpdateAmount,
    // initialize a Google Pay 'PaymentsClient' object
    initPaymentsClient,
    getPaymentsClient
};
